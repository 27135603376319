// tslint:disable:member-ordering

import BPromise from 'bluebird'
import _ from 'lodash'
import moment from 'moment'
import qs from 'query-string'
import { v4 as uuidv4 } from 'uuid'

import { isUUIDValid } from '../helpers/utils'
import { JSONSchemaResolver } from '../resolvers/json-schema-resolver'
import { Entity } from './entity'
import { EventEmitter } from './event-emitter'
import { EntitySchemaProps } from './prop-constants'
import { SchemaIds } from './schema'
import { orderMixins } from './utils'

// should really look into this: https://github.com/pubkey/rxdb

export const DEFAULT_QUERY_METADATA = {
  offset: 0,
  shouldIncludeLeafEntities: true,
  size: 80,
  maxSizePerGroup: 10,
}

export const DEFAULT_ORDERS = [
  {
    path: 'creationDate',
    type: 'descending',
  },
]

export enum RequestMethod {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  GET = 'GET',
  DELETE = 'DELETE',
}

export enum EntitySource {
  LOCAL = 'local',
  REMOTE = 'remote',
}

export interface ISaveRecordRequest {
  url: string
  method: string
  body: any
  isNew: any
  entityId: string
  record: any
  useOfflineQueue?: boolean
  dependencies?: any
}

export class Store extends EventEmitter {
  public static RECORD_PENDING = 'RECORD_PENDING'
  public static RECORD_CREATED = 'RECORD_CREATED'
  public static RECORD_CHANGED = 'RECORD_CHANGED'
  public static RECORD_MODIFIED = 'RECORD_MODIFIED'
  public static RECORD_DELETED = 'RECORD_DELETED'
  public static RECORD_RESET = 'RECORD_RESET'
  public static RELOAD_COLLECTION = 'RELOAD_COLLECTION'

  protected api: any
  protected baseUrl: string
  protected host: string
  protected indexUrl: string
  protected shouldCacheRecord: boolean
  protected identityMap: Record<string, any>
  protected secondaryIdentityMap: Record<string, any>
  protected externallyChangedEntities = new Set<string>()
  protected crawledSchemas = new Set<string>()
  protected uuidsWaitingToLoad = new Set<string>()
  protected loadingUuidsToPromises: Record<string, any>

  constructor(api, host, shouldCacheRecord = false) {
    super()
    this.api = api
    this.setHost(host)
    this.shouldCacheRecord = shouldCacheRecord
    this.identityMap = {}
    // TODO(Peter): this is a hack for metadata uri
    this.secondaryIdentityMap = {}
    this.loadingUuidsToPromises = {}
  }

  public setHost(host) {
    this.host = host
    this.baseUrl = `${host}/1.0/entities/records`
    this.indexUrl = this.baseUrl
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // Public API
  /// ///////////////////////////////////////////////////////////////////////////

  public getCachedEntities(): Record<string, any> {
    return this.identityMap
  }

  public getSchemas(): Record<string, any> {
    return this.secondaryIdentityMap
  }

  public clearCache() {
    this.identityMap = {}
    this.secondaryIdentityMap = {}
  }

  /**
   * Fetches an entity *ONLY* from the internal cache. Note that
   * this method does *NOT* check if the entity has been updated on the server.
   * You should use getOrFetchRecord, or fetchOrGetRecord for that.
   * @param id The entity id of the record to fetch
   * @returns the entity, or undefined if not found
   */
  public getRecord(id: string): Entity {
    return this.identityMap[id] || this.secondaryIdentityMap[id]
  }

  /**
   * See {@link getRecord}
   * @param ids The entity ids of the record to fetch
   * @returns the entities, or empty array if not found
   */
  public getRecords(ids: string[]): Entity[] {
    if (_.isEmpty(ids)) {
      return BPromise.resolve([])
    }
    return _.map(ids, (id) => this.getRecord(id))
  }

  /** Convert entity in json into Entity object and save into store */
  public resolveRecords(jsons, shouldCache = true) {
    return this.resolveMixins(jsons).then(() => this.materializeRecords(jsons, shouldCache))
  }

  public findRecord(id) {
    const url = `${this.baseUrl}/${id}`
    return this.api
      .getJSON(url)
      .then((json) => this.resolveMixin(json))
      .then((json) => this.materializeRecord(json))
      .then((json) => {
        this.externallyChangedEntities.delete(id)
        return json
      })
  }

  public findRecordThrottled(id) {
    return new Promise((resolve, reject) => { // todo - onCancel? what if the client component unmounts?
      this.uuidsWaitingToLoad.add(id)

      if (!_.isArray(this.loadingUuidsToPromises[id])) {
        this.loadingUuidsToPromises[id] = []
      }

      this.loadingUuidsToPromises[id].push([resolve, reject])

      this._findRecordThrottled().catch(reject) // todo - does this fully handle rejections? failure cases? what if 1 entity out of 50 fail, can we retry the remainder?
    })
  }

  public _findRecordThrottled = _.throttle(async () => {
    const ids = Array.from(this.uuidsWaitingToLoad)
    this.uuidsWaitingToLoad.clear()

    await this.findRecords(ids)

    ids.forEach(id => {
      const promises = this.loadingUuidsToPromises[id]
      if (!promises) { // SKIP - an earlier fn call already loaded this UUID and resolved the promises
        return
      }

      promises.forEach(promise => {
        const [resolve, _] = promise

        resolve(this.getRecord(id))
      })

      delete this.loadingUuidsToPromises[id]
    })
  }, 200)

  /** Lazily fetch and completely resolve a schema, if needed. */
  public resolveSchema(uri: string) {
    const schema = this.getRecord(uri)
    if (schema) {
      // pessimistically resolve mixins just in case any deeper mixins are still
      // missing.
      return this.resolveMixins([schema]).then(() => schema)
    } else {
      return this.findSchema([uri])
    }
  }

  /**
   * A wrapper to get the schema either by id or by its name
   */
  public findSchema(idOrUri) {
    const getSchema = isUUIDValid(idOrUri)
      ? this.api.getRecord.bind(this.api)
      : this.api.getSchemasByUri.bind(this.api)
    return getSchema(idOrUri)
      .then((schemas) => this.resolveMixin(_.isArray(schemas) ? _.first(schemas) : schemas))
      .then((json) => this.materializeRecord(json))
  }

  public async findSchemas(uris) {
    if (_.isEmpty(uris)) {
      return []
    }
    let jsons = await this.api.getSchemasByUri(uris)
    jsons = await this.resolveMixins(jsons)
    return await this.materializeRecords(jsons, true)
  }

  public findRecords(ids, props: any = {}) {
    const { shouldCacheRecord, shouldMaterialize = true, shouldResolveMixins = true } = props
    if (_.isEmpty(ids)) {
      return BPromise.resolve([])
    }
    const url = `${this.baseUrl}/_mget`
    const body = _.map(ids, (id) => ({ entityId: id }))
    return this.api
      .postJSON(url, body)
      .then((json) => {
        return shouldResolveMixins ? this.resolveMixins(json) : json
      })
      .then((json) => {
        return shouldMaterialize ? this.materializeRecords(json, shouldCacheRecord) : json
      })
      .then((json) => {
        _.forEach(ids, (id) => this.externallyChangedEntities.delete(id))
        return json
      })
  }

  public getOrFetchRecord(id) {
    const entity = this.externallyChangedEntities.has(id) ? null : this.getRecord(id)
    if (entity) {
      return BPromise.resolve(entity)
    } else {
      return this.findRecord(id)
    }
  }

  public getOrFetchRecords(ids) {
    // TODO: Doesn't this code as written make two copies of each found entity?
    const entities = _.map(ids, (id) => {
      return this.externallyChangedEntities.has(id) ? null : this.getRecord(id)
    })
    const unresolvedIndices = {}
    _.forEach(ids, (id, idx) => {
      const record = this.externallyChangedEntities.has(id) ? null : this.getRecord(id)
      entities.push(record)
      if (!record) {
        unresolvedIndices[id] = idx
      }
    })
    const unresolvedIds = Object.keys(unresolvedIndices)
    const resolution = unresolvedIds.length
      ? this.findRecords(unresolvedIds, { shouldCacheRecord: true })
      : BPromise.resolve([])
    return resolution.then((resolvedRecords) => {
      _.forEach(resolvedRecords, (record) => {
        const id = record.get('uniqueId')
        const idx = unresolvedIndices[id]
        entities[idx] = record
      })
      return BPromise.resolve(entities)
    })
  }

  // TODO: refactor this, and getOrFetch, findRecord, and getRecord into a common interface with a cache policy.
  public fetchOrGetRecord(id) {
    return this.findRecord(id)
  }

  public fetchOrGetRecords(ids) {
    return this.findRecords(ids)
  }

  public queryRecords(query, stripsMetadata = false) {
    const queryJSON = _.assign({}, query, {
      metadata: {
        ...DEFAULT_QUERY_METADATA,
        ...query.metadata,
      },
    })
    const getEntitiesFromResult = (json, entities) => {
      if (!_.isEmpty(json.children)) {
        _.forEach(json.children, (child) => getEntitiesFromResult(child, entities))
      } else if (json.data) {
        // if it is a leaf node, we want to materialize the entity in json.data
        entities.push(json.data)
      }
      return entities
    }
    const materializeResultJSON = (json) => {
      if (!_.isEmpty(json.children)) {
        _.remove(json.children, (child: any) => {
          // exclude leaf nodes whose data is not a json object
          return _.isEmpty(child.children) && !_.isObject(child.data)
        })
        json.children = _.map(json.children, materializeResultJSON)
        return json
      } else if (json.data) {
        // if it is a leaf node, we want to materialize the entity in json.data
        json.data = this.materializeRecord(json.data)
        return stripsMetadata ? json.data : json
      }
      return json
    }

    return this.api
      .postJSON(`${this.host}/1.0/entities/query?${this.getDebugString(query)}`, queryJSON)
      .then((json) => {
        const entities = getEntitiesFromResult(json, [])
        return this.resolveMixins(entities).then(() => {
          return materializeResultJSON(json)
        })
      })
  }

  public getDebugString(query) {
    if (_.get(query, 'debug.context')) {
      return qs.stringify({ DEBUG: query.debug.context })
    }

    return ''
  }

  public queryInsight(query) {
    return this.api.postJSON(`${this.host}/1.0/entities/query?${this.getDebugString(query)}`, query)
  }

  // more cowbell to fetch the entity since findRecord/getRecord/fetchRecord are clearly not enough
  public async getEntity(entityId: string, sources = [EntitySource.REMOTE, EntitySource.LOCAL]) {
    return this.findRecord(entityId)
  }

  public async getEntities(
    entityIds: string[],
    sources = [EntitySource.REMOTE, EntitySource.LOCAL]
  ) {
    return this.findRecords(entityIds)
  }

  /**
   * Get the schema locally or remotely and resolve dependencies(child schemas) if any.
   * @param uris
   * @param sources
   */
  public async getEntitySchemasWithDependencies(
    uris: string[],
    sources: EntitySource[] = [EntitySource.LOCAL, EntitySource.REMOTE]
  ) {
    const getMethods = {
      [EntitySource.LOCAL]: (ids: string[]) => {
        return this.getRecords(ids)
      },
      [EntitySource.REMOTE]: (ids: string[]) => {
        return this.resolveEntitiesByUri(uris)
      },
    }
    return this.getEntityImpl(uris, getMethods, sources)
  }

  public async getEntitySchemas(uris: string[], sources?: EntitySource[]) {
    if (!sources) {
      sources = [EntitySource.LOCAL, EntitySource.REMOTE]
    }
    return this.api.getSchemasByUri(uris)
  }

  private async getEntityImpl(ids: any, methods: any, sources: EntitySource[]): Promise<any[]> {
    let allValues: any[] = []

    for (const source of sources) {
      try {
        const value = await _.get(methods, source)?.(ids)
        const normalizedValue = _.compact(value)

        // if primary source failed to resolve the entity, the fallback should able resolve it
        allValues = _.unionBy(allValues, normalizedValue, 'uniqueId')

        // if able to get all entities from a source, then return, if not run the fallback
        if (_.size(normalizedValue) === _.size(ids)) {
          break
        }
      } catch (err: any) {
        console.warn('Unable to get entity', ids, source, err)
      }
    }
    return allValues
  }

  public markEntitiesForRefetch(ids: string[]) {
    _.forEach(ids, (id) => this.externallyChangedEntities.add(id))
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // Public Mutations
  /// ///////////////////////////////////////////////////////////////////////////

  public getDefaultEntityJSON(defaultValue) {
    return {
      ...defaultValue,
      mixins: {
        active: [],
        inactive: [],
      },
      status: {
        state: Entity.Status.New,
      },
      uniqueId: uuidv4(),
    }
  }

  public createRecord(entity, defaultValue?) {
    if (entity.isStoryboardPlan) {
      return this.createRecordFromStoryboardPlan(entity, defaultValue)
    }
    return this.createRecordFromSchema(entity, defaultValue)
  }

  public createRecordFromSchema(schema, defaultValue) {
    const allOfMixins = this.getAllOfMixins(schema, [])
    const anyOfMixins = this.getAnyOfMixins(schema, [])
    if (anyOfMixins.length > 1) {
      console.error(`${schema.title} that has more than one anyOf mixins`)
    }
    const data = this.getDefaultEntityJSON(defaultValue)
    const record = new Entity(data, this.api)
    _.forEach(allOfMixins, (metadata) => record.addMixin(metadata))
    _.forEach(anyOfMixins, (metadata) => record.addMixin(metadata))
    orderMixins(this, record)
    record.applyDefaults()

    // We only want the entity to be dirty if there was a `defaultValue` passed
    // We don't want other new entities to be dirty because we want to be able to cancel
    // a creation editor without being asked if we are sure.
    if (!defaultValue) {
      record.setPrevContent(record.content)
    }

    return record
  }

  public createRecordFromStoryboardPlan(storyboardPlan: any, defaultValue) {
    const executionSchemaId = storyboardPlan.get(
      'core_storyboard_plan.execution.entityId',
      SchemaIds.STORYBOARD_EXECUTION
    )
    const executionSchema = this.getRecord(executionSchemaId)
    return this.createRecordFromSchema(executionSchema, {
      ...defaultValue,
      core_storyboard_execution: {
        plan: {
          displayName: _.get(storyboardPlan, 'core_storyboard_plan.name'),
          entityId: storyboardPlan.uniqueId,
        },
        permissions: _.get(storyboardPlan, 'core_storyboard_plan.permissions'),
        status: 'Pending',
        /**
         * create a first initial event for json patching purpose.  The server will
         * trim out the empty entry for cleaner changeset, but that would cause a potential issue
         * with json patch.  On the client side, it the event is null, then the json patch for the first
         * event if there is no dummy event would be
         *
         * "ops": "add"
         * "path": "/events"
         * "value": [{xxx}]
         *
         * which basically override the current events with the array of one element.  This is not
         * feasible since if this patch is send later than other patches from other clients, it will
         * not append to the log but instead override with the patch value.
         *
         * On the other hand, if we try to append the first event with this patch
         *
         * "ops": "add"
         * "path": "/events/-"
         * "value": {xxx}
         *
         * It will attempt to append to current events log, but since the server trim all the empty
         * fields, the json patch apply will fail due "events" is not existed due to the trimming.
         * It will generate this error "parent of node to add does not exist"
         *
         * Since the server will trim the json on request and before saving it to the database, we
         * cannot use a normal trigger to inject the empty "events: []" back to the json, so it
         * is cleaner to create an dummy event on creation for cleaner json patch
         */
        events: [
          {
            id: 'dummy_placeholder_event',
            eventType: 'breadcrumb',
            creationDate: moment().toISOString(),
          },
        ],
        settings: _.get(storyboardPlan, 'core_storyboard_plan.settings', {}),
      },
    })
  }

  /**
   * Create entities and set its default values from the given schema/storyboard plan ids
   *
   * @param entityIds schema or storyboard plan
   * @param defaultValues blob of data that contains either 'preFilledValues.<mixinIds>',
   *   'entityOptions.<mixinIds>.defaultValues', schema path 'documenet.name' or
   *    '<mixinIds>.document.name'.  The logic hopefully filters out the noise and correctly
   *    set the default values
   *
   * @returns newly created entities
   */
  public async createEntities(entityIds, defaultValues = {}) {
    let finalEntityIds = entityIds

    try {
      const resolvedEntities = await this.getEntities(entityIds)

      const schemas = _.filter(resolvedEntities, (entity) => entity.isSchema)
      const executionSchemas = []

      // to avoid cloning outside of plan usecase
      if (_.filter(resolvedEntities, (entity) => entity.isStoryboardPlan).length > 0) {
        finalEntityIds = _.clone(entityIds)
      }
      // fetch active workflows
      for (const entity of resolvedEntities) {
        if (entity.isStoryboardPlan) {
          // create local copy to mutate
          const plan = entity
          const planId = plan.uniqueId
          const activePlan = await this.getActivePlan(plan)
          const activePlanId = activePlan.uniqueId

          // replace inactive plan with active plan
          if (planId != activePlanId) {
            _.remove(finalEntityIds, (id) => plan.uniqueId == id)
            finalEntityIds.push(activePlan.uniqueId)
          }

          const storyboardExecutionId = _.get(activePlan, 'core_storyboard_plan.execution.entityId')
          const executionSchema = await this.getEntity(storyboardExecutionId)
          executionSchemas.push(executionSchema)
        }
      }

      const resolvedSchemas = await this.resolveMissingSchemas(
        [...schemas, ...executionSchemas],
        {}
      )

      return _.map(finalEntityIds, (entityId) => {
        // at this point, all missing schemas should be resolved already
        const metadata = this.getRecord(entityId)
        const record = this.createRecord(metadata)
        return record.withDefaultValues(defaultValues)
      })
    } catch (err) {
      console.warn('Unable to create entity for types', entityIds, err)
    }

    return []
  }

  private async getActivePlan(plan) {
    const containerId = _.get(plan, 'core_storyboard_plan.version.container.entityId')
    if (containerId == null) {
      return plan
    }
    const container = await this.getEntity(containerId)
    if (container == null) {
      console.error(`container ${containerId} does not exist`)
      throw new Error(`container ${containerId} does not exist`)
    }
    const activePlanId = _.get(container, 'core_storyboard_plansContainer.activePlan.entityId')
    if (activePlanId !== plan.uniqueId) {
      const activePlan = await this.getEntity(activePlanId)
      if (activePlan == null) {
        console.error(`plan ${activePlanId} does not exist`)
        throw new Error(`plan ${activePlanId} does not exist`)
      } else {
        return activePlan
      }
    } else {
      return plan
    }
  }

  public async createWorkflow(planEntity, defaultValues = {}) {
    try {
      const storyboardExecutionId = _.get(planEntity, 'core_storyboard_plan.execution.entityId')
      const executionSchema = await this.getEntity(storyboardExecutionId)

      const resolvedSchemas = await this.resolveMissingSchemas([executionSchema], {})

      // at this point, all missing schemas should be resolved already
      const planRecord = this.getRecord(planEntity.uniqueId)
      const record = this.createRecordFromStoryboardPlan(planRecord, null)
      return record.withDefaultValues(defaultValues)
    } catch (err) {
      console.error('Unable to create entity for types', planEntity.uniqueId, err)
    }
  }

  private emitWhenIndexed(event, record: Entity) {
    // Polls remotely to see if Elasticsearch has indexed this entity, by comparing indexed timestamps.
    // "_metadata._remote" is only updated during a find/reload, so we can use that to compare against.
    // If remote timestamp is same or newer, then ES either caught up to a recent change, or the entity
    // is newer.
    return record
      .waitUntil(
        (entity) => {
          const remoteModifiedDate = _.get(entity, '_metadata._remote.modifiedDate')
          const modifiedDate = entity.get('modifiedDate')
          return (
            record != entity ||
            (remoteModifiedDate && moment(remoteModifiedDate).isSameOrAfter(moment(modifiedDate)))
          )
        },
        2000,
        2000
      )
      .then((record) => {
        this.emit(event, record)
        record.emit(event, record)
      })
  }

  public deleteRecord(record: Entity) {
    const id = this.getIdentifier(record)
    const url = `${this.baseUrl}/${id}`
    return this.api.deleteJSON(url).then((json) => {
      record.set('isDeleted', true)
      if (json) {
        record.setContent(json)
        record.setPrevContent(json)
      }
      this.emit(Store.RECORD_DELETED, record)
      record.emit(Store.RECORD_DELETED, record)
      this.emitWhenIndexed(Store.RECORD_DELETED, record).then(() => {
        // defer removal until index pipeline is finished
        delete this.identityMap[id]
      })
    })
  }

  public undeleteRecord(record: Entity) {
    const id = this.getIdentifier(record)
    const url = `${this.baseUrl}/undelete/${id}`
    return this.api.postJSON(url).then((json) => {
      record.set('isDeleted', false)
      record.setContent(json)
      record.setPrevContent(json)
      if (this.shouldCacheRecord) {
        this.cacheRecord(record)
      }
      this.emit(Store.RECORD_CREATED, record)
      record.emit(Store.RECORD_CREATED, record)
      this.emitWhenIndexed(Store.RECORD_CREATED, record)
    })
  }

  public retryRecord(record) {
    const id = this.getIdentifier(record)
    const url = `${this.baseUrl}/retry/${id}`
    return this.api.postJSON(url)
  }

  public invalidateRecord(record) {
    const id = this.getIdentifier(record)
    const url = `${this.baseUrl}/invalidate/${id}`
    return this.api.postJSON(url)
  }

  public recomputeRecord(record) {
    const id = this.getIdentifier(record)
    const url = `${this.baseUrl}/recompute/${id}`
    return this.api.postJSON(url)
  }

  public updateJSON(record, json, onProgress?) {
    const formData = new FormData()
    formData.append('entity', JSON.stringify(json))
    return this.api.putMultipart(this.baseUrl, formData, onProgress).then(() => {
      this.emit(Store.RECORD_CHANGED, record)
      record.emit(Store.RECORD_CHANGED, record)
      this.emitWhenIndexed(Store.RECORD_CHANGED, record)
      return record
    })
  }

  public saveRecord(
    record,
    props?,
    method: RequestMethod = RequestMethod.PUT,
    patchContent = null
  ) {
    if (!record.isDirty && _.isEmpty(patchContent)) {
      return BPromise.resolve(record)
    }
    // need to save a temp variable before posting, because json returned from
    // server will have busy status
    const isNewRecord = record.isNew
    return this.sendRecord(record, props, method, patchContent).then((record) => {
      if (isNewRecord) {
        if (this.shouldCacheRecord) {
          this.cacheRecord(record)
        }
        this.emit(Store.RECORD_CREATED, record)
        record.emit(Store.RECORD_CREATED, record)
        this.emitWhenIndexed(Store.RECORD_CREATED, record)
      } else {
        this.emit(Store.RECORD_CHANGED, record)
        record.emit(Store.RECORD_CHANGED, record)
        this.emitWhenIndexed(Store.RECORD_CHANGED, record)
      }
      return record
    })
  }

  public saveRecords(records) {
    return this.postRecords(records).then(() => {
      records.forEach((record) => {
        if (record.isNew) {
          if (this.shouldCacheRecord) {
            this.cacheRecord(record)
          }
          this.emit(Store.RECORD_CREATED, record)
          record.emit(Store.RECORD_CREATED, record)
        } else {
          this.emit(Store.RECORD_CHANGED, record)
          record.emit(Store.RECORD_CHANGED, record)
        }
      })
    })
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // Helper Methods
  /// ///////////////////////////////////////////////////////////////////////////

  public cacheRecord(record) {
    const id = this.getIdentifier(record)
    const secondaryId = this.getSecondaryIdentifier(record)
    this.identityMap[id] = record
    if (secondaryId) {
      this.secondaryIdentityMap[secondaryId] = record
    }
  }

  public cacheRecords(records, cacheRecordFn = this.cacheRecord.bind(this)) {
    _.forEach(records, (record) => cacheRecordFn(record))
  }

  public async resolveMixins(jsons) {
    const unresolvedSchemaIds = this.getUnresolvedActiveMixinIds(jsons)

    // Previously, in the fix for VD-7987, we did not consider the case where
    // `jsons` might contain a schema entity. This led to
    // https://withvector.atlassian.net/browse/VD-8592, where one of the
    // schema's `allOf` items was still missing. Instead, we need to fall
    // through and not return early.
    const records = await this.findRecords(unresolvedSchemaIds, {
      shouldCacheRecord: true,
      shouldResolveMixins: false,
    })

    // In order to fully resolve schemas, we must also lazily fetch their missing
    // allOf/anyOf schemas. Otherwise, chains of dependent schemas can be left
    // unresolved (see https://withvector.atlassian.net/browse/VD-7987). This
    // helps avoid WSOD in web-app when users in other firms are shared docs but
    // their own firm's bundle doesn't have all the necessary doc types needed
    // for pre-fetching.
    const unresolvedSchemaUris = this.getUnresolvedSchemaUris(_.concat(jsons, records))
    await this.findSchemas(unresolvedSchemaUris)
    return jsons
  }

  protected materializeRecord(json, shouldCacheRecord = this.shouldCacheRecord): Entity {
    const id = this.getIdentifier(json)
    let record: Entity = this.identityMap[id]
    if (!shouldCacheRecord || !record) {
      record = new Entity(json, this.api)
    } else {
      this.materializeContent(record, json)
    }
    _.set(record, '_metadata._remote.modifiedDate', _.get(json, 'modifiedDate'))
    if (shouldCacheRecord) {
      this.cacheRecord(record)
    }

    return record
  }

  protected materializeContent(record: Entity, json: any): void {
    // 1. Update if local record isPending (mobile) or isNew (web). Whatever is from
    //    from the server must be newer. We put in this check in case local clock is
    //    not correct so we cannot depend on just the timestamp
    // 2. Update if modified date is the same but status is different (server does not
    //    update modifiedDate when status changes)
    // 3. Update if record from server is newer
    // 4. Update if local record is not dirty (we don't want to blow away
    //    local changes, unless it's a schema entity)
    const isRecordNew = record.isPending || record.isNew
    const isStatusChanged =
      record.get('modifiedDate') === json.modifiedDate &&
      record.get('status.state') !== _.get(json, 'status.state')
    const isJSONNewer =
      record.get('modifiedDate') !== json.modifiedDate &&
      moment(record.get('modifiedDate')).isBefore(moment(json.modifiedDate))

    if ((isRecordNew || isStatusChanged || isJSONNewer) && (!record.isDirty || record.isSchema)) {
      record.setAllContents(json)
      record.emit(Store.RECORD_CHANGED, record)
    } else {
      // origContent is the content from the server, so always sync it
      record.setOrigContent(json)
    }
  }

  protected materializeRecords(array, shouldCacheRecord) {
    return _.map(array, (item) => {
      return this.materializeRecord(item, shouldCacheRecord)
    })
  }

  protected getIdentifier(record) {
    return record.uniqueId
  }

  protected getSecondaryIdentifier(record) {
    return record.id
  }

  protected postRecord(record, props: any = {}) {
    return this.sendRecord(record, props, RequestMethod.PUT)
  }

  protected postRecords(records) {
    const formData = new FormData()
    const entityOperationWireModels = []
    records.forEach((record) => {
      entityOperationWireModels.push({
        opType: 'create_or_update',
        entityJson: record.content,
      })
    })
    formData.append('entity', JSON.stringify({ entityOperationWireModels }))
    return this.api.putMultipart(`${this.baseUrl}/transact`, formData).then((json) => {
      console.log('HEARD BACK')
      console.log(json)
    })
  }

  protected patchRecord(record, props: any = {}) {
    return this.sendRecord(record, props, RequestMethod.PATCH)
  }

  protected sendRecord(
    record,
    props: any = {},
    method: RequestMethod = RequestMethod.PUT,
    patchContent = null
  ) {
    const request = this.normalizeRequest(record, props, method, patchContent)

    const normalizedRecord = request.record
    const { onProgress, jobTag, saveTaskId } = props
    const formData = new FormData()
    const multipartFiles = normalizedRecord.multipartFiles
    formData.append('entity', JSON.stringify(request.body))
    _.forEach(multipartFiles, (files, key) => {
      _.forEach(files, (file) => {
        formData.append(`file:${file.uniqueId}`, file.file)
      })
    })

    return this.api
      .sendMultipartRequest(request.method, request.url, formData, onProgress, jobTag, saveTaskId)
      .then((json) => {
        record.clearMultipartFiles()
        record.setAllContents(json)
        return record
      })
  }

  protected resolveMixin(json) {
    return this.resolveMixins([json]).then(_.first)
  }

  protected normalizeRequest(
    record,
    props: any = {},
    saveMethod: RequestMethod = RequestMethod.PUT,
    patchContent = null
  ): ISaveRecordRequest {
    const isNew = record.isNew
    const isPatch = saveMethod === RequestMethod.PATCH
    const method = !isNew && isPatch ? RequestMethod.PATCH : saveMethod

    let url = this.baseUrl
    let body = record.content

    if (method === RequestMethod.PATCH) {
      const organicPatch = patchContent || record.jsonPatch(null, null, props)
      const { syntheticPatch = [] } = props
      // organic patch has higher precedent
      body = record.normalizeJsonPatchForServer([...syntheticPatch, ...organicPatch])
      url = url + '/' + record.uniqueId
    }
    return {
      body: body,
      dependencies: record.dependenciesForSave,
      entityId: record.uniqueId,
      isNew,
      method,
      record,
      url,
    }
  }

  private getAllOfMixins(schema, result) {
    const uris = _.map(schema.get(EntitySchemaProps.ALL_OF, []), JSONSchemaResolver.REF_KEY)
    uris.forEach((uri) => {
      const metadata = this.getRecord(uri)
      if (!metadata) {
        throw new Error(`Cannot find schema with id=${uri}`)
      }
      this.getAllOfMixins(metadata, result)
      if (!_.find(result, { uniqueId: metadata.uniqueId })) {
        result.push(metadata)
      }
    })
    // don't forget to add the schema itself
    if (!_.find(result, { uniqueId: schema.uniqueId })) {
      result.push(schema)
    }
    return result
  }

  private getAnyOfMixins(schema, result) {
    const uris = _.map(schema.get(EntitySchemaProps.ANY_OF, []), JSONSchemaResolver.REF_KEY)
    uris.forEach((uri) => {
      const metadata = this.getRecord(uri)
      if (!metadata) {
        throw new Error(`Cannot find metadata with id=${uri}`)
      }
      result.push(metadata)
    })
    return result
  }

  // TODO: rename to a more generic name like getUnresolvedDependencyIds
  private getUnresolvedActiveMixinIds(jsons: any[]): string[] {
    const schemaIds = _.reduce(
      jsons,
      (result, json) => {
        const activeMixins = _.get(json, 'mixins.active')
        result[json.uniqueId] = true

        const dependenciesIds = _.map(activeMixins, 'entityId')
        if (_.some(activeMixins, { entityId: SchemaIds.STORYBOARD_EXECUTION })) {
          dependenciesIds.push(json?.core_storyboard_execution?.plan?.entityId)
        }

        _.forEach(dependenciesIds, (entityId) => {
          const metadata = this.getRecord(entityId)
          if (!metadata && !result[entityId]) {
            result[entityId] = false
          }
        })
        return result
      },
      {}
    )

    return _.reduce(
      schemaIds,
      (result, resolved, id) => {
        if (!resolved) {
          result.push(id)
        }
        return result
      },
      []
    )
  }

  private getUnresolvedSchemaUris(jsons: any[]): string[] {
    const uris = _.reduce(
      jsons,
      (acc, json) => {
        const allOfRefs = _.map(_.get(json, EntitySchemaProps.ALL_OF), JSONSchemaResolver.REF_KEY)
        const anyOfRefs = _.map(_.get(json, EntitySchemaProps.ANY_OF), JSONSchemaResolver.REF_KEY)
        acc.push(...allOfRefs)
        acc.push(...anyOfRefs)
        return acc
      },
      []
    )
    return _.filter(uris, (uri) => {
      return !this.getRecord(uri)
    })
  }

  public resolveEntitiesById(ids, sources?: EntitySource[]) {
    return this.findRecords(ids, {
      shouldCacheRecord: false,
      shouldMaterialize: false,
      shouldResolveMixins: false,
    }).then((jsons) => {
      if (ids.length !== jsons.length) {
        console.warn(
          'Store::resolveEntitiesById() : ' +
            `requested ${ids.length} entities but received ${jsons.length} entities`
        )
      }
      // NOTE: when we are bootstrapping, we don't have all the schemas yet
      // which prevents us from properly materializing the records, so we
      // first fetch the schemas, cache manually without wrapping in Entity
      // Now that we have all the schemas in the cache we can manually
      // materialize and cache the records
      this.cacheRecords(jsons)
      const entities = _.map(jsons, (json) => new Entity(json, this.api))
      this.cacheRecords(entities)

      const schemasByUri = {}
      return this.resolveMissingSchemas(entities, schemasByUri, sources).then(() => entities)
    })
  }

  public async resolveEntitiesByUri(uris, sources: EntitySource[] = [EntitySource.REMOTE]) {
    return this.findSchemas(uris).then((entities) => {
      const schemasByUri = {}
      return this.resolveMissingSchemas(entities, schemasByUri, sources).then(() => entities)
    })
  }

  public async resolveMissingSchemas(currentDepthEntities, schemasByUri, sources?: EntitySource[]) {
    currentDepthEntities.forEach((e) => {
      schemasByUri[e.get(EntitySchemaProps.URI)] = e
    })

    const resolver = new JSONSchemaResolver(this.api)

    const deeperUris = new Set<string>()
    currentDepthEntities.forEach((e) => {
      const parentSchemaUris = [
        ..._.map(e.get(EntitySchemaProps.ALL_OF), JSONSchemaResolver.REF_KEY),
        ..._.map(e.get(EntitySchemaProps.ANY_OF), JSONSchemaResolver.REF_KEY),
      ]
      parentSchemaUris.forEach((uri) => {
        if (!schemasByUri[uri]) {
          deeperUris.add(uri)
        }
      })

      // crawl for missing $refs
      if (e.id && !this.crawledSchemas.has(e.id)) {
        const refUrls = {}
        resolver.findRemoteRefsInObject(e.content, refUrls)
        _.forEach(refUrls, ($, url) => {
          if (!schemasByUri[url]) {
            deeperUris.add(url)
          }
        })
        this.crawledSchemas.add(e.id)
      }
    })

    if (!deeperUris.size) {
      return BPromise.resolve([])
    }

    const schemas = await this.getEntitySchemas(Array.from(deeperUris), sources)

    const schemaEntities = schemas.map((schema) =>
      schema instanceof Entity ? schema : new Entity(schema, this.api)
    )

    this.cacheRecords(schemaEntities)
    return this.resolveMissingSchemas(schemaEntities, schemasByUri)
  }
}
